import { Grid, GridItem, GridProps, Text } from "@chakra-ui/react";
import { ClientReferenceModel, LoanRequestModel } from "@obtainly-v2/schema";
import { FC } from "react";

interface ClientReferenceDetailsProps extends GridProps {
  clientReference: ClientReferenceModel;
  loanRequest?: LoanRequestModel;
}

export const ClientReferenceDetails: FC<ClientReferenceDetailsProps> = ({
  clientReference,
  loanRequest,
  ...props
}) => {
  return (
    <Grid w="full" alignItems="stretch" gap="15px" {...props}>
      <GridItem>
        <Text fontSize="sm" color="gray.600" fontWeight="medium">
          LPO Issued by
        </Text>
        <Text>{clientReference?.client?.name}</Text>
      </GridItem>

      <GridItem>
        <Text fontSize="sm" color="gray.600" fontWeight="medium">
          Contact Name
        </Text>
        <Text>{loanRequest?.referenceName ?? clientReference.name}</Text>
      </GridItem>

      <GridItem>
        <Text fontSize="sm" color="gray.600" fontWeight="medium">
          Contact Email
        </Text>
        <Text>{loanRequest?.referenceEmail ?? clientReference.email}</Text>
      </GridItem>

      <GridItem>
        <Text fontSize="sm" color="gray.600" fontWeight="medium">
          Contact Role
        </Text>
        <Text>{loanRequest?.referenceRole ?? clientReference.role}</Text>
      </GridItem>
    </Grid>
  );
};
