import { Button, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BusinessType, ClientReferenceStatus } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  ClientReferenceModel,
  UserModel,
} from "@obtainly-v2/schema";
import { BusinessAutocomplete } from "components/business";
import { FormBaseProps, FormField, FormGroup } from "components/common";
import { emailDomainBlacklist } from "config/email";
import { useFormWithSchema } from "hooks";
import { useToast } from "hooks/useToast";
import React from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { clientReferenceService } from "services";
import * as Yup from "yup";

interface ClientReferenceFormProps
  extends FormBaseProps<ClientReferenceFormInputType, ClientReferenceModel> {
  vendorId?: string;
  clientId?: string;
  suggestedName?: string;
  clearSuggestedName?: VoidFunction;
  user?: UserModel;
  admin?: AdministratorModel;
  clientReference?: ClientReferenceModel;
}

const emailFilterRegex = new RegExp(
  `@(${emailDomainBlacklist.join("|")})[\.a-zA-Z]*$`
);

const clientRefFormSchema = Yup.object({
  status: Yup.string().required(),
  name: Yup.string().min(3).required(),
  email: Yup.string()
    .email()
    .test(
      "test valid email",
      "Email must be company owned e.g name@yourclientcompany.com",
      (email) => !!email && !emailFilterRegex.test(email)
    )
    .required()
    .label("Contact email"),
  role: Yup.string().required(),
  vendorId: Yup.string().required(),
  clientId: Yup.string().required(),
});

export type ClientReferenceFormInputType = Yup.InferType<
  typeof clientRefFormSchema
>;

export const ClientReferenceForm: React.FC<ClientReferenceFormProps> = ({
  vendorId,
  clientId,
  suggestedName,
  clearSuggestedName,
  user,
  admin,
  clientReference,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const { toast } = useToast();
  const defaultValues: ClientReferenceFormInputType = {
    name: clientReference?.name || suggestedName || "",
    status: clientReference?.status || ClientReferenceStatus.Pending,
    vendorId: clientReference?.vendorId || vendorId || "",
    clientId: clientReference?.clientId || clientId || "",
    email: clientReference?.email || "",
    role: clientReference?.role || "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    reset: resetForm,
  } = useFormWithSchema(clientRefFormSchema, {
    defaultValues,
    resolver: (data, context, options) => {
      return yupResolver(clientRefFormSchema)(data, context, options);
    },
  });

  const {
    mutate: mutateClientReferenceCreate,
    isLoading: isCreatingReference,
  } = useMutation(clientReferenceService.create);
  const {
    mutate: mutateClientReferenceUpdate,
    isLoading: isUpdatingReference,
  } = useMutation(clientReferenceService.update);

  const [isLoading, setLoading] = React.useState(false);

  const isSubmittingForm = React.useMemo(() => {
    return isCreatingReference || isUpdatingReference || isLoading;
  }, [isCreatingReference, isUpdatingReference, isLoading]);

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    onError?.(new Error(message));
    toast({
      description: message,
      status: "error",
    });
  };

  const successHandler = (
    formValues: ClientReferenceFormInputType,
    data: ClientReferenceModel,
    toastMessage: string
  ) => {
    onSuccess?.(data);
    resetForm(formValues);
    toast({
      description: toastMessage,
      status: "success",
    });
  };

  const commitChanges = (values: ClientReferenceFormInputType) => {
    const serializedValues: Partial<Record<keyof ClientReferenceModel, any>> = {
      ...values,
    };
    if (clientReference) {
      const payload = { ...clientReference, ...serializedValues };
      mutateClientReferenceUpdate(payload, {
        onSuccess: () => successHandler(values, payload, "Updated"),
        onError: errorHandler,
      });
    } else {
      mutateClientReferenceCreate(serializedValues, {
        onSuccess: ({ data }) => successHandler(defaultValues, data, "Created"),
        onError: errorHandler,
      });
    }
  };

  const onFormSubmit = (values: ClientReferenceFormInputType) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(values)
        .then((data) => resetForm(data))
        .finally(() => setLoading(false));
    } else {
      commitChanges(values);
    }
  };

  const formSubmitAction = (event: React.FormEvent<HTMLFormElement>) => {
    // should this from be used in another form (permissible ONLY in a PORTAL)
    // stop event propagation to parent
    event.stopPropagation();
    handleSubmit(onFormSubmit)(event);
  };

  return (
    <form onSubmit={formSubmitAction}>
      <VStack spacing="10px" alignItems="stretch">
        {!!clientReference && !!admin && (
          <FormGroup label="Status">
            <FormField
              as="select"
              autoComplete="client-reference-status"
              placeholder="Select status"
              errorMessage={errors.status?.message}
              {...register("status")}
            >
              {Object.entries(ClientReferenceStatus).map(([key, status]) => (
                <option key={key} value={status}>
                  {key}
                </option>
              ))}
            </FormField>
          </FormGroup>
        )}

        <FormGroup label="Contact Name">
          <FormField
            autoComplete="client-reference-name"
            placeholder={`Enter contact name`}
            errorMessage={errors.name?.message}
            {...register("name")}
          />
        </FormGroup>

        <FormGroup label="Contact Email">
          <FormField
            autoComplete="client-reference-email"
            placeholder={`Enter contact email`}
            errorMessage={errors.email?.message}
            {...register("email")}
          />
        </FormGroup>
        <FormGroup label="Contact Role">
          <FormField
            autoComplete="client-reference-role"
            placeholder={`Enter contact role ex. Procurement Manager`}
            errorMessage={errors.role?.message}
            {...register("role")}
          />
        </FormGroup>

        {!clientId && (
          <FormGroup label="Client">
            <Controller
              name="clientId"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => (
                <BusinessAutocomplete
                  businessType={BusinessType.Client}
                  onChange={(data) => onChange({ target: data })}
                  {...rest}
                />
              )}
            />
          </FormGroup>
        )}

        {(!vendorId || !!admin) && (
          <FormGroup label="Vendor">
            <Controller
              name="vendorId"
              control={control}
              render={({ field: { ref, onChange, ...rest } }) => (
                <BusinessAutocomplete
                  businessType={BusinessType.Vendor}
                  onChange={(data) => onChange({ target: data })}
                  isDisabled={!!vendorId}
                  {...rest}
                />
              )}
            />
          </FormGroup>
        )}

        {/* action buttons */}
        <Button
          isLoading={isSubmittingForm}
          disabled={isSubmittingForm || !isValid || !isDirty}
          type="submit"
          data-test="submit-login"
          colorScheme="primary"
        >
          {!clientReference ? "Create" : "Update"} Contact
        </Button>

        <Button
          disabled={isSubmittingForm}
          data-test="reset-login"
          onClick={() => {
            clearSuggestedName?.();
            resetForm(defaultValues);
          }}
        >
          Discard
        </Button>
      </VStack>
    </form>
  );
};
