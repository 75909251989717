import { Badge, BadgeProps } from "@chakra-ui/react";
import { ClientReferenceStatus } from "@obtainly-v2/enums";

export const ClientReferenceStatusBadge = ({
  status,
  ...badgeProps
}: BadgeProps & {
  status: ClientReferenceStatus;
}) => {
  return (
    <Badge
      colorScheme={
        status === ClientReferenceStatus.Verified
          ? "success"
          : status === ClientReferenceStatus.Rejected
          ? "critical"
          : "gray"
      }
      {...badgeProps}
    >
      {status}
    </Badge>
  );
};
