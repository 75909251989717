import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ClientReferenceModel } from "@obtainly-v2/schema";
import { AutocompleteProps, FormSelectList } from "components/common";
import { useAuth } from "hooks";
import React from "react";
import { useQuery } from "react-query";
import { clientReferenceService } from "services";
import { ClientReferenceForm } from "./ClientReferenceForm";

export interface ClientReferenceAutocompleteProps extends AutocompleteProps {
  vendorId?: string;
  clientId?: string;
  errorMessage?: string;
}

export const ClientReferenceAutocomplete: React.FC<
  ClientReferenceAutocompleteProps
> = ({ vendorId, clientId, isDisabled, errorMessage, ...rest }) => {
  const {
    auth: { admin },
  } = useAuth();
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  const [selectMode, setSelectMode] = React.useState<boolean>(false);

  const { data: clientReferences, isLoading: isReadingAll } = useQuery(
    `client_reference_by_vendorId_${vendorId}_clientId_${clientId}`,
    () => clientReferenceService.readManyBy({ vendorId, clientId }),
    {
      enabled: !!vendorId && !!clientId && !selectMode,
    }
  );

  const selectedReference = clientReferences?.find(
    (reference) => reference._id === rest.value
  );

  const onSuccessCreate = (data: ClientReferenceModel) => {
    const newOption = {
      value: data._id,
      label: data.name,
    };
    rest?.onChange(newOption);
    setOptions((options) => [...options, newOption]);
    toggleSelectMode();
  };

  const toggleSelectMode = () => setSelectMode((prevMode) => !prevMode);

  React.useEffect(() => {
    const newBusinesses =
      clientReferences
        ?.filter((reference) => reference.vendorId === vendorId)
        ?.map((reference) => ({
          label: reference.name,
          value: reference._id,
        })) || [];
    setOptions(newBusinesses);
  }, [clientReferences, vendorId]);

  return (
    <>
      <Box
        p="15px"
        bgColor="white"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="6px"
      >
        {!!selectedReference && (
          <Box mb="10px">
            <Text fontWeight="medium">{selectedReference.name}</Text>
            <Text fontSize="sm" color="gray.700">
              Email: {selectedReference.email}
            </Text>
            <Text fontSize="sm" color="gray.700">
              Role: {selectedReference.role}
            </Text>
          </Box>
        )}

        <Button
          size="sm"
          colorScheme="primary"
          variant="link"
          onClick={toggleSelectMode}
          disabled={isReadingAll}
        >
          {!!selectedReference ? "Change" : "Select"} Client Contact
        </Button>
      </Box>
      {!!errorMessage && (
        <Text fontSize="sm" color="red.500" mt="2px">
          {errorMessage}
        </Text>
      )}
      <Modal isOpen={selectMode} onClose={() => setSelectMode(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody h="auto" maxH="auto">
            <FormSelectList
              label="Client Contact List"
              helperText="Search name of person in your client's contact list"
              value={rest.value}
              options={options}
              onChange={(_, data) => {
                rest.onChange(data);
                toggleSelectMode();
              }}
              isLoading={isReadingAll}
              renderCreateComponent={(query, onCreateNewListItem) => (
                <ClientReferenceForm
                  vendorId={vendorId}
                  clientId={clientId}
                  admin={admin!}
                  suggestedName={query}
                  clearSuggestedName={onCreateNewListItem}
                  onSuccess={(data) => {
                    onSuccessCreate(data);
                    onCreateNewListItem();
                  }}
                />
              )}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
