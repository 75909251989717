import { Box, Button, Link } from "@chakra-ui/react";
import { ClientReferenceModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Table } from "components/common/Table";
import { routes } from "config/routes";
import { useTableSort } from "hooks";
import NextLink from "next/link";
import { FC, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { clientReferenceService } from "services";
import { formatUnixTime } from "utils";
import { ClientReferenceStatusBadge } from "./ClientReferenceStatusBadge";
interface ClientReferencesTableProps {
  hiddenColumns?: (
    | "status"
    | "name"
    | "role"
    | "email"
    | "vendor-name"
    | "client-name"
    | "createdAt"
    | "updatedAt"
    | "actions"
  )[];
}

export const ClientReferencesTable: FC<ClientReferencesTableProps> = ({
  hiddenColumns,
}) => {
  const { tableQueryProps, updateTableQueryProps, initializeSorting } =
    useTableSort({
      page: 1,
      sort: { id: "createdAt", desc: true },
    });

  const { data: clientReferences, isLoading } = useQuery(
    "client_reference_read_all",
    clientReferenceService.readAll
  );

  const columns = useMemo(() => {
    const defaultColumns: Column<ClientReferenceModel>[] = [
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <ClientReferenceStatusBadge status={value} />
        ),
      },
      { id: "name", Header: "Name", accessor: "name" },
      { id: "role", Header: "Role", accessor: "role" },
      { id: "email", Header: "Email", accessor: "email" },
      {
        id: "vendor-name",
        Header: "Vendor (Company Name)",
        accessor: "vendor",
        Cell: ({ cell }) => <>{cell.value?.name}</>,
      },
      {
        id: "client-name",
        Header: "Client (Company Name)",
        accessor: "client",
        Cell: ({ cell }) => <>{cell.value?.name}</>,
      },
      {
        id: "createdAt",
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ cell }) => <>{formatUnixTime(cell.value * 1000)}</>,
      },
      {
        id: "updatedAt",
        Header: "Updated",
        accessor: "updatedAt",
        Cell: ({ cell }) => <>{formatUnixTime(cell.value * 1000)}</>,
      },
      {
        id: "actions",
        Header: "Actions",
        Cell: ({ row }: any) => (
          <NextLink
            href={`${routes.admin.clientReferences}/${row.original?._id}`}
            passHref
          >
            <Link>
              <Button size="sm">View</Button>
            </Link>
          </NextLink>
        ),
      },
    ];

    if (hiddenColumns?.length) {
      return defaultColumns.filter(
        (column: any) => !hiddenColumns.includes(column.id)
      );
    }

    return defaultColumns;
  }, [hiddenColumns]);

  useEffect(() => {
    if (!clientReferences?.length) return;
    initializeSorting();
  }, [clientReferences, initializeSorting]);

  return (
    <Box overflowX="auto">
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!clientReferences?.length}
        errorMessage="No client reference was found"
      />
      {!!clientReferences?.length && (
        <Table
          columns={columns}
          data={clientReferences}
          queryProps={{
            ...tableQueryProps,
            onQueryChange: updateTableQueryProps,
          }}
        />
      )}
    </Box>
  );
};
